import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useRef, useState } from 'react'
import Select from '../select/select.component'
import Input from '../input/input.component'
import ubigeoStyles from './ubigeo.module.scss'
import { isEmpty } from './ubigeo.utils'

export default function Ubigeo({ required, onChange }) {
   const [current, setCurrent] = useState({
      country: '',
      state: '',
      city: '',
      cyties: [],
   })
   const [isClicked, setIsClicked] = useState(false)

   const cytiesRef = useRef(null)

   console.log('required', required)

   const { states, cyties, countries } = useStaticQuery(graphql`
      query {
         states: allUsStatesJson(sort: { fields: name }) {
            nodes {
               name
               abbreviation
            }
         }

         cyties: allUsCitiesJson(sort: { fields: state }) {
            nodes {
               state
               city
            }
         }

         countries: allCountriesJson {
            nodes {
               name
               val
            }
         }
      }
   `)

   const handleClick = useCallback(
      val => {
         console.log('------- val', val)
         //console.log("------- current", current);

         const _cyties = cyties.nodes
            .filter(node => node.state === val.name && val.select === 'state')
            .map(val => {
               return { name: val.city, value: val.city }
            })

         if (val.select === 'country') {
            setCurrent({ state: '', city: '', country: val.name })
         }

         if (val.select === 'state') {
            hideShowSelect()
            setCurrent({
               ...current,
               city: '',
               state: val.name,
               cyties: _cyties,
            })
         }

         if (val.select === 'city') {
            setCurrent({ ...current, city: val.name })
         }

         setTimeout(() => {
            onChange &&
               onChange({
                  name: val.name,
                  value: val.value,
                  select: val.select,
                  current: validateCurrentData(val, current),
               })
         }, 300)
      },
      [current, onChange]
   )

   const validateCurrentData = (val, current) => {
      const selected = {
         country: current.country,
         state: current.state,
         city: current.city,
      }

      if (val.select === 'country' && val.name !== current.country) {
         selected.country = val.name
         selected.state = ''
         selected.city = ''
      }

      if (val.select === 'state' && val.name !== current.state) {
         selected.country = current.country
         selected.state = val.name
         selected.city = ''
      }

      if (val.select === 'city' && val.name !== current.city) {
         selected.country = current.country
         selected.state = current.state
         selected.city = val.name
      }

      return {
         country: selected.country,
         state: selected.state,
         city: selected.city,
      }
   }

   const hideShowSelect = useCallback(() => {
      setIsClicked(true)
      setTimeout(() => {
         setIsClicked(false)
      }, 200)
   }, [current, onChange])

   const fieldValidation = (object, name) => {
      return !isEmpty(object) && object[name] ? true : false
   }

   return (
      <div className={ubigeoStyles.container}>
         <div className={ubigeoStyles.wrap}>
            <Select
               label='Select a Country'
               data={countries.nodes}
               name='country'
               showRequiredLine={fieldValidation(required, 'country')}
               onChange={handleClick}
               //required={required.country}
            />
         </div>

         {current.country !== 'United States' && current.country !== 'null' && (
            <>
               <Input
                  label='Country'
                  name='country'
                  placeholder='Title'
                  minLength='2'
                  maxLength='30'
                  type='text'
                  required={required.country}
                  value={current.country !== 'Other' ? current.country : ''}
                  onChange={(name, value) => handleClick({ name, value })}
               />
               <Input
                  label='State'
                  name='state'
                  placeholder='Title'
                  type='text'
                  minLength='2'
                  maxLength='30'
                  required={required.state}
                  value={current.country !== 'Other' ? current.state : ''}
                  onChange={(name, value) => handleClick({ name, value })}
               />

               <Input
                  label='City'
                  name='city'
                  placeholder='Title'
                  type='text'
                  minLength='2'
                  maxLength='30'
                  required={required.city}
                  value={current.country !== 'Other' ? current.city : ''}
                  onChange={(name, value) => handleClick({ name, value })}
               />
            </>
         )}

         {current.country === 'United States' && (
            <div className={ubigeoStyles.wrap}>
               <Select
                  label='Select a State'
                  data={states.nodes}
                  name='state'
                  onChange={handleClick}
                  showRequiredLine={fieldValidation(required, 'state')}
                  //required={required.state}
               />
            </div>
         )}

         {!isClicked &&
            current.country === 'United States' &&
            current.state &&
            current.cyties.length > 0 && (
               <div className={ubigeoStyles.wrap}>
                  <Select
                     ref={cytiesRef}
                     label={`Select a City of ${current.state}`}
                     data={current.cyties}
                     name='city'
                     showRequiredLine={fieldValidation(required, 'city')}
                     //required={required.city}
                     onChange={handleClick}
                  />
               </div>
            )}
      </div>
   )
}
