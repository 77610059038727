import React, { useEffect, useState } from 'react'
import inputStyles from './input.module.scss'

const _data = [
   { name: 'Yes', value: 'yes' },
   { name: 'No', value: 'no' },
]

export default function Input({
   label,
   name,
   placeholder,
   minLength = 2,
   type = 'text',
   data = _data,
   onChange,
   showRequiredLine,
   ...otherProps
}) {
   const [inputError, setInputError] = useState(null)

   useEffect(() => {
      const wrapper = document.querySelector(`.input-${name}`)
      wrapper.addEventListener('mouseover', handleClickOutside)
      return () => {
         wrapper.removeEventListener('mouseover', handleClickOutside)
      }
   })

   const handleClickOutside = () => {
      document.querySelector(`.input-${name}`).focus()
   }

   //const toTitleCase = str => {
   //return str.replace(/\w\S*/g, function (txt) {
   //return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
   //})
   //}

   const validateField = (field, value) => {
      let isValid = null
      switch (field) {
         case 'email':
            isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            setInputError(isValid ? '' : `Is invalid ${field}`)
            break
         case 'dimensions':
         case 'framedDimensions':
            isValid = value.match(/^[0-9]{1,2}x[0-9]{1,2}x[0-9]{1,2}?$/i)
            setInputError(isValid ? '' : `Don't match the format `)
            break
         case 'edition':
         case 'dheight':
         case 'dwidth':
         case 'ddepth':
         case 'fheight':
         case 'fwidth':
         case 'fdepth':
            isValid = value.match(/^\d+$/i)
            setInputError(isValid ? '' : `Isn't a number`)
            break
         case 'date':
            isValid =
               value.match(/^(((0)[0-9])|((1)[0-2]))(\/)(19|20)\d{2}$/i) ||
               value.match(/(19|20)\d{2}$/i)
            setInputError(isValid ? '' : `Don't match the format `)
            break
         case 'acquisition':
            isValid = value.match(
               /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}/i
            )
            setInputError(isValid ? '' : `Don't match the format `)
            break
         case 'telephone':
            isValid = value.match(/^(\d{3})(\-)(\d{3})(\-)(\d{4})$/i)
            setInputError(isValid ? '' : `Is not valid phone format`)
            break
         case 'onlyText':
            //not used
            isValid =
               value.length >= minLength && value.match(/^[A-Za-z_ .]+$/i)
            setInputError(
               isValid ? '' : `Text is too short or not match the format`
            )
            break
         default:
            isValid = value.length >= minLength
            setInputError(isValid ? '' : `Text is too short`)
            break
      }
   }

   const handleChange = e => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value

      validateField(name, value)
      onChange && onChange(name, value)
   }

   /*
    * Multiple option
    * Type radio or checkbox
    * */
   const OptionsInput = ({
      name,
      label,
      type = 'radio',
      data = [],
      onChange,
      ...otherProps
   }) => {
      //const [current, setCurrent] = useStahe()
      return (
         <div className={`${inputStyles.options} ${inputStyles.row}`}>
            <h5 className={inputStyles.label}>{label}</h5>
            <div
               className={`${inputStyles.column} ${
                  otherProps.vertical ? inputStyles.vertical : ''
               }`}
            >
               {data.map((element, key) => {
                  return (
                     <div className={inputStyles.radioItem} key={key}>
                        <input
                           className={`input-${name}`}
                           type={type}
                           name={name}
                           value={element.value}
                           checked={
                              element.value.toLowerCase() ===
                              otherProps.current.toLowerCase()
                           }
                           onChange={e => handleChange(e, key)}
                           {...otherProps}
                        />
                        <label>{element.name}</label>
                     </div>
                  )
               })}
            </div>
         </div>
      )
   }
   /*
    * Text <input type="text"/>
    * Type text, tel, password, etc
    * */
   const TextInput = ({
      name,
      label,
      placeholder,
      onChange,
      ...otherProps
   }) => {
      return (
         <div className={`${inputStyles.text} ${inputStyles.row}`}>
            {label && (
               <label className={inputStyles.label} htmlFor={name}>
                  {label}
               </label>
            )}
            <input
               className={`${inputStyles.focus} input-${name}`}
               type={type}
               name={name}
               placeholder={placeholder ? placeholder : label}
               onChange={e => handleChange(e)}
               {...otherProps}
            />
            {inputError && (
               <span className={inputStyles.alert}>{inputError}</span>
            )}
         </div>
      )
   }
   return (
      <div
         className={`${inputStyles.container} ${
            showRequiredLine ? inputStyles.required : ''
         }`}
      >
         {type !== 'radio' && type !== 'checkbox' ? (
            <TextInput
               name={name}
               type={type}
               label={label}
               placeholder={placeholder}
               onChange={onChange}
               {...otherProps}
            />
         ) : (
            <OptionsInput
               type={type}
               name={name}
               label={label}
               data={data}
               onChange={onChange}
               {...otherProps}
            />
         )}
      </div>
   )
}
