import React from 'react'
import galleryStylesHorizontal from './gallery.horizontal.module.scss'
import { data } from './gallery.horizontal.data.js'
import Img from 'gatsby-image'

export default function GalleryHorizontal({
  content = data,
  isMoving,
  onClick,
  isDark,
}) {
  const action = e => {
    onClick && onClick(e)
  }
  return (
    <div
      className={`
        ${galleryStylesHorizontal.container} 
        ${isMoving ? galleryStylesHorizontal.small : ''}
        ${isDark ? galleryStylesHorizontal.dark : ''}
     `}
    >
      <ul
        className={`
          ${galleryStylesHorizontal.list} 
          ${
            content.length > 6 &&
            content.length < 10 &&
            galleryStylesHorizontal.short
          }
          ${content.length > 10 ? galleryStylesHorizontal.scroll : ''}
        `}
      >
        {content.map((element, key) => {
          const isImage =
            element.relationships.imgMobile &&
            element.relationships.imgMobile.localFile

          if (!!isImage) {
            const sources = [
              element.relationships.imgMobile.localFile.childImageSharp.fluid,
              {
                ...element.relationships.imgDescktop.localFile.childImageSharp
                  .fluid,
                media: `(min-width: 600px)`,
              },
            ]
            return (
              <li className={galleryStylesHorizontal.element} key={key}>
                <button onClick={() => action(element.field_section_label)}>
                  <Img
                    fluid={sources}
                    className={galleryStylesHorizontal.shadow}
                    alt={element.field_section_label}
                  />
                  <span>{element.field_section_label}</span>
                </button>
              </li>
            )
          }
          return null
        })}
      </ul>
      <h2>{content.name}</h2>
    </div>
  )
}
