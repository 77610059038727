import React, { Component } from 'react'
import Input from '../input/input.component'
import Button from '../button/button.component'
import submissionsStyles from './submissions.module.scss'
import {
   submissionsState,
   clasificationData,
   mediumData,
   supportData,
   funitData,
   isEmpty,
} from './submissions.utils'
import FileBase64 from '../fileBase64/fileBase64.component'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Ubigeo from '../ubigeo/ubigeo.component'
import Select from '../select/select.component'

class Submissions extends Component {
   state = {
      ...submissionsState,
      isSending: false,
      isSended: false,
      maxfilesize: undefined,
      areRequired: [],
      error: false,
   }

   handleClick = (name, value, maxfilesize) => {
      console.log(
         '------- Submissions --------- Handleclick Select name:',
         name,
         'value:',
         value,
         'maxfilesize:',
         maxfilesize
      )

      let info = []

      if (name === 'images') {
         console.log('value images', value)

         value = value.map(val => {
            console.log(
               'value',
               val,
               val.weight,
               maxfilesize,
               val.weight < maxfilesize
            )
            info.push({
               name: val.name,
               filemime: val.type,
            })
            return val
         })
      }

      //if (
      //(name === 'country' && value === 'Other') ||
      //value === 'United States'
      //) {
      //this.setState({
      //state: { ...this.state['state'], value: '' },
      //city: { ...this.state['city'], value: '' },
      //})
      //}

      //console.log('country: ', countries)

      // from custom select
      if (typeof name === 'object') {
         if (!isEmpty(name.current)) {
            //console.log('/////////current', name.current)
            Object.keys(name.current).forEach(country => {
               this.setState(prevState => {
                  if (prevState[country].value !== country) {
                     return {
                        [country]: {
                           ...prevState[country],
                           value: name.current[country],
                        },
                     }
                  }
               })
            })
         } else {
            value = name?.name
            name = name?.select
         }
      }

      this.setState(
         prevState => {
            if (prevState[name] !== value) {
               return {
                  [name]: {
                     ...prevState[name],
                     value,
                     info,
                  },
               }
            }
         },
         () => {
            Object.keys(this.state).forEach(ele => {
               if (this.state[ele] && this.state[ele].children) {
                  //
                  this.state[ele].children.forEach(child_name => {
                     //console.log('child_name', child_name)
                     this.setState(prevState => {
                        const children = prevState[child_name]
                        //console.log('children', child_name, children)

                        if (children && value === 'yes') {
                           return {
                              [child_name]: {
                                 ...prevState[child_name],
                                 required: true,
                              },
                           }
                        } else if (children && value === 'no') {
                           //console.log('----------', child_name, name)
                           return {
                              [child_name]: {
                                 ...prevState[child_name],
                                 required: false,
                              },
                           }
                        }
                     })
                  })
               }
            })
            this.validate()
         }
      )
   }

   //handleSubmit = e => {
   //e.preventDefault()

   //this.setState({ isSending: true }, () => {
   //[> Send data <]
   //setTimeout(() => {
   //console.log('Sended state', this.state)
   //this.sendData(this.state)
   //}, 500)
   //})
   //}
   //encodedString = str => new Buffer(str).toString('base64')

   filter = (Object.filter = (obj, predicate) =>
      Object.keys(obj)
         .filter(key => predicate(obj[key]))
         .reduce(
            (res, key) =>
               Object.assign(res, { [key.toLowerCase()]: obj[key].value }),
            {}
         ))

   handleClickSendForm = e => {
      e.preventDefault()
      this.validate()
      const { areRequired } = this.state

      console.log('validation', areRequired)

      let requiredList = null
      if (!isEmpty(areRequired)) {
         requiredList = []
         console.log('validation requiredList')
         Object.keys(areRequired).forEach(field => {
            if (!isEmpty(areRequired[field])) requiredList.push(true)
         })
      }

      console.log('requiredList', requiredList)
      if (Array.isArray(requiredList) && requiredList.length === 0) {
         this.setState({ isSending: true }, () => {
            // Send data
            setTimeout(() => {
               console.log('Sended state', this.state)
               this.sendData(this.state)
            }, 500)
         })
      }
   }

   validate = () => {
      const fields = {}
      Object.keys(this.state).forEach(key => {
         fields[key] =
            this.state[key]?.required &&
            !this.state[key]?.value &&
            this.state[key]

         if (!fields[key]) {
            fields[key] = Array.isArray(this.state[key]?.value)
               ? this.state[key].value.length > 0
                  ? false
                  : this.state[key]
               : false
         }
      })
      this.setState({ areRequired: fields }, () => {
         console.log('............... state', this.state)
      })
      return fields
   }

   fieldValidation = (object, name) => {
      //console.log('fieldValidation ' + name, object)
      if (Array.isArray(name)) {
         const elements = {}
         name.forEach(key => {
            elements[key] = !isEmpty(object) && object[key] ? true : false
         })
         return elements
      }
      if (Array.isArray(object[name]?.value)) {
         return object[name].value.length > 0 ? false : true
      }
      return !isEmpty(object) && object[name] ? true : false
   }

   sendData = data => {
      const myHeaders = new Headers()

      const formData = new FormData()
      console.log('formData', formData)

      const filtered = this.filter(data, item => item && item.value !== '')
      console.log('data', data)

      Object.keys(filtered).forEach(key => {
         if (key === 'images') {
            console.log(filtered[key])
            filtered[key].map(val => {
               !!val && formData.append(key, val.file, val.name)
            })
         } else {
            formData.append(key, filtered[key])
         }
      })

      if (this.state.framed.value === 'yes') formData.append('isFramed', true)

      const requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formData,
         redirect: 'follow',
      }
      fetch(
         //`${process.env.GATSBY_API_URL}webform_rest/submit?_format=json`,
         `${process.env.NODE_API}send_form`,
         requestOptions
      )
         .then(response => {
            return response.text()
         })
         .then(result => {
            setTimeout(() => {
               this.setState({ isSended: true })
            }, 1000)
            console.info(result)
            return result
         })
         .catch(error => {
            console.error(error)
            this.setState({ isSending: false, isSended: false, error })
         })
   }

   render() {
      const {
         clasification,
         title,
         isDated,
         date,
         medium,
         support,
         owner,
         dheight,
         dwidth,
         ddepth,
         dunit,
         images,
         edition,
         framed,
         fheight,
         fwidth,
         fdepth,
         funit,
         additionalNotes,
         exhibitionHistory,
         publicationHistory,
         documentationSale,
         signed,
         signature,
         inscription,
         inscriptionLocation,
         labels,
         labelsList,
         acquisition,
         acquiredFrom,
         firstName,
         lastName,
         address,
         city,
         state,
         country,
         postalCode,
         creditline,
         telephone,
         email,
         areRequired,
         isSended,
         isSending,
      } = this.state

      return !isSending ? (
         <form
            id='submissions'
            //onSubmit={this.handleSubmit}
            className={submissionsStyles.container}
            encType='multipart/form-data'
         >
            <div className={submissionsStyles.wrapper}>
               <h3>Work Details</h3>

               <div className={`${submissionsStyles.firstSelected}`}>
                  <Select
                     label='Select a Work Classification/Type'
                     showLabel={false}
                     showRequiredLine={this.fieldValidation(
                        areRequired,
                        'clasification'
                     )}
                     data={clasificationData}
                     name='clasification'
                     onChange={this.handleClick}
                     showLine
                     required={clasification.required}
                  />
               </div>

               <Input
                  label='Title'
                  type='text'
                  name='title'
                  minLength='5'
                  maxLength='64'
                  required={title.required}
                  value={title.value}
                  showRequiredLine={this.fieldValidation(areRequired, 'title')}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               {this.state.clasification.value === 'Print' && (
                  <Input
                     label='Edition Number'
                     name='edition'
                     type='number'
                     pattern='[-+]?[0-9]'
                     required={edition.required}
                     value={edition.value}
                     onChange={(name, value) => this.handleClick(name, value)}
                  />
               )}
               <Input
                  type='radio'
                  label='Date'
                  name='isDated'
                  current={isDated.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               {this.state.isDated.value === 'yes' && (
                  <Input
                     label='Date (MM/YYYY or YYYY)'
                     name='date'
                     placeholder='Date'
                     type='text'
                     minLength='4'
                     maxLength='7'
                     required={date.required}
                     value={date.value}
                     onChange={(name, value) => this.handleClick(name, value)}
                  />
               )}

               <div className={submissionsStyles.wrap}>
                  <Select
                     label='Medium'
                     data={mediumData}
                     name='medium'
                     showRequiredLine={this.fieldValidation(
                        areRequired,
                        'medium'
                     )}
                     onChange={this.handleClick}
                     required={medium.required}
                  />
               </div>

               <div className={submissionsStyles.wrap}>
                  <Select
                     label='Support'
                     data={supportData}
                     name='support'
                     showRequiredLine={this.fieldValidation(
                        areRequired,
                        'support'
                     )}
                     onChange={this.handleClick}
                     required={support.required}
                  />
               </div>

               <div className={submissionsStyles.wrap}>
                  <h4>Dimensions (Height x Width x Depth)</h4>

                  <div className={submissionsStyles.inLine}>
                     <Input
                        label='Height'
                        name='dheight'
                        placeholder='Height'
                        pattern='[0-9]{1,4}'
                        type='text'
                        required={dheight.required}
                        value={dheight.value}
                        showRequiredLine={this.fieldValidation(
                           areRequired,
                           'dheight'
                        )}
                        onChange={(name, value) =>
                           this.handleClick(name, value)
                        }
                     />

                     <Input
                        label='Width'
                        name='dwidth'
                        placeholder='Width'
                        pattern='[0-9]{1,4}'
                        type='text'
                        required={dwidth.required}
                        value={dwidth.value}
                        showRequiredLine={this.fieldValidation(
                           areRequired,
                           'dwidth'
                        )}
                        onChange={(name, value) =>
                           this.handleClick(name, value)
                        }
                     />

                     <Input
                        label='Depth'
                        name='ddepth'
                        placeholder='Depth'
                        pattern='[0-9]{1,4}'
                        type='text'
                        required={ddepth.required}
                        value={ddepth.value}
                        showRequiredLine={this.fieldValidation(
                           areRequired,
                           'ddepth'
                        )}
                        onChange={(name, value) =>
                           this.handleClick(name, value)
                        }
                     />

                     <Select
                        label='Select a unit of measure'
                        showLabel={false}
                        data={funitData}
                        name='dunit'
                        showRequiredLine={this.fieldValidation(
                           areRequired,
                           'dunit'
                        )}
                        onChange={this.handleClick}
                        required={dunit.required}
                     />
                  </div>
               </div>

               <Input
                  type='radio'
                  label='Framed'
                  name='framed'
                  current={framed.value}
                  showRequiredLine={this.fieldValidation(areRequired, 'framed')}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               {this.state.framed.value === 'yes' && (
                  <div className={submissionsStyles.wrap}>
                     <h4>Framed Dimensions (Height x Width x Depth)</h4>

                     <div className={submissionsStyles.inLine}>
                        <Input
                           label='Height'
                           name='fheight'
                           placeholder='Height'
                           pattern='[0-9]{1,4}'
                           type='text'
                           required={fheight.required}
                           value={fheight.value}
                           showRequiredLine={this.fieldValidation(
                              areRequired,
                              'fheight'
                           )}
                           onChange={(name, value) =>
                              this.handleClick(name, value)
                           }
                        />

                        <Input
                           label='Width'
                           name='fwidth'
                           placeholder='Width'
                           pattern='[0-9]{1,4}'
                           type='text'
                           required={fwidth.required}
                           value={fwidth.value}
                           showRequiredLine={this.fieldValidation(
                              areRequired,
                              'fwidth'
                           )}
                           onChange={(name, value) =>
                              this.handleClick(name, value)
                           }
                        />

                        <Input
                           label='Depth'
                           name='fdepth'
                           placeholder='Depth'
                           pattern='[0-9]{1,4}'
                           type='text'
                           required={fdepth.required}
                           value={fdepth.value}
                           showRequiredLine={this.fieldValidation(
                              areRequired,
                              'fdepth'
                           )}
                           onChange={(name, value) =>
                              this.handleClick(name, value)
                           }
                        />

                        <Select
                           label='Select a unit of measure'
                           showLabel={false}
                           data={funitData}
                           name='funit'
                           showRequiredLine={this.fieldValidation(
                              areRequired,
                              'funit'
                           )}
                           onChange={this.handleClick}
                           required={funit.required}
                        />
                     </div>
                  </div>
               )}

               <Input
                  type='radio'
                  label='Signed (Location and Material)'
                  name='signed'
                  current={signed.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               {this.state.signed.value === 'yes' && (
                  <Input
                     label='Signature (Where and how)'
                     name='signature'
                     placeholder='Title'
                     type='text'
                     minLength='5'
                     maxLength='64'
                     showRequiredLine={this.fieldValidation(
                        areRequired,
                        'signature'
                     )}
                     required={signature.required}
                     value={signature.value}
                     onChange={(name, value) => this.handleClick(name, value)}
                  />
               )}

               <Input
                  type='radio'
                  label='Inscription (Location and text)'
                  name='inscription'
                  minLength='5'
                  maxLength='64'
                  current={inscription.value}
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'inscription'
                  )}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               {this.state.inscription.value === 'yes' && (
                  <Input
                     label='Inscription (Where and how)'
                     name='inscriptionLocation'
                     placeholder='Title'
                     type='text'
                     minLength='5'
                     maxLength='64'
                     required={inscriptionLocation.required}
                     value={inscriptionLocation.value}
                     onChange={(name, value) => this.handleClick(name, value)}
                  />
               )}

               <Input
                  label='Labels'
                  name='labels'
                  type='radio'
                  minLength='5'
                  maxLength='64'
                  current={labels.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               {this.state.labels.value === 'yes' && (
                  <Input
                     label='Label (Please list)'
                     name='labelsList'
                     placeholder='Title'
                     type='text'
                     minLength='5'
                     maxLength='64'
                     required={labelsList.required}
                     value={labelsList.value}
                     onChange={(name, value) => this.handleClick(name, value)}
                  />
               )}

               <Input
                  label='Additional Notes'
                  name='additionalNotes'
                  placeholder='Additional information about the work'
                  type='text'
                  minLength='5'
                  maxLength='256'
                  required={additionalNotes.required}
                  value={additionalNotes.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <Input
                  label='Exhibition History'
                  name='exhibitionHistory'
                  placeholder='List all known exhibitions for the work'
                  type='text'
                  minLength='5'
                  maxLength='256'
                  required={exhibitionHistory.required}
                  value={exhibitionHistory.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <Input
                  label='Publication History'
                  name='publicationHistory'
                  placeholder='List all known exhibitions for the work'
                  type='text'
                  minLength='5'
                  maxLength='256'
                  required={publicationHistory.required}
                  value={publicationHistory.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />
            </div>

            <div className={submissionsStyles.wrapper}>
               <h3>Provenance</h3>

               <Input
                  label='Acquisition Date (MM/DD/YYYY)'
                  name='acquisition'
                  placeholder='MM/DD/YYYY'
                  type='text'
                  required={acquisition.required}
                  value={acquisition.value}
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'acquisition'
                  )}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <Input
                  label='Documentation of Sale'
                  name='documentationSale'
                  type='radio'
                  current={documentationSale.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />
               <Input
                  label='Acquired From (e.g., auction, gallery, inherited)'
                  name='acquiredFrom'
                  placeholder='(e.g., auction, gallery, inherited)'
                  type='text'
                  minLength='3'
                  maxLength='64'
                  required={acquiredFrom.required}
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'acquiredFrom'
                  )}
                  value={acquiredFrom.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />
            </div>

            <div
               className={`${submissionsStyles.wrapper} ${submissionsStyles.files}`}
            >
               <h3>Images</h3>
               <label htmlFor='images'>
                  Please upload an image of the front and the back of the work.
                  If visible, upload detail images of all signatures,
                  inscriptions, and labels. (Please upload only jpg or png file
                  formats.)
               </label>

               {images.info && images.info.length > 1 && (
                  <p className={`${submissionsStyles.fileList}`}>
                     {/* List images text */}
                     {images.info.map((val, key) => {
                        let total = images.value.length
                        return `${val.name} ${
                           total - 1 === Number(key) ? '' : ' ,'
                        }`
                     })}
                  </p>
               )}
               <FileBase64
                  multiple={true}
                  ondone={(value, maxfilesize) =>
                     this.handleClick('images', value, maxfilesize)
                  }
                  showRequiredLine={this.fieldValidation(areRequired, 'images')}
                  required={images.required}
                  accept='.jpg, .png, jpeg'
                  maxfilesize={(1024 * (1024 * 1)) / 2}
               />
            </div>

            <div className={submissionsStyles.wrapper}>
               <h3>Contact Information</h3>

               <Input
                  type='radio'
                  label=''
                  name='owner'
                  vertical='true'
                  data={[
                     { name: 'Owner', value: 'Owner' },
                     {
                        name: 'Authorized Representative',
                        value: 'Authorized Representative',
                     },
                  ]}
                  current={owner.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <Input
                  label='First Name'
                  name='firstName'
                  placeholder='Title'
                  type='text'
                  minLength='3'
                  maxLength='64'
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'firstName'
                  )}
                  required={firstName.required}
                  value={firstName.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <Input
                  label='Last Name'
                  name='lastName'
                  placeholder='Title'
                  type='text'
                  minLength='3'
                  maxLength='64'
                  required={lastName.required}
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'lastName'
                  )}
                  value={lastName.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <Input
                  label='Address'
                  name='address'
                  placeholder='Title'
                  type='text'
                  minLength='5'
                  maxLength='64'
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'address'
                  )}
                  required={address.required}
                  value={address.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               {
                  // Ubigeo on change country, state, city
               }

               <Ubigeo
                  required={this.fieldValidation(areRequired, [
                     'city',
                     'country',
                     'state',
                  ])}
                  onChange={(name, value, select, countries) =>
                     this.handleClick(name, value, select, countries)
                  }
               />

               <Input
                  label='Zip / Postal Code'
                  name='postalCode'
                  placeholder='Title'
                  minLength='2'
                  maxLength='30'
                  type='text'
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'postalCode'
                  )}
                  required={postalCode.required}
                  value={postalCode.value}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <div
                  className={`${submissionsStyles.wrap} ${submissionsStyles.phone}`}
               >
                  <label>Telephone</label>
                  <PhoneInput
                     className={`${submissionsStyles.phoneInput} ${
                        this.fieldValidation(areRequired, 'telephone')
                           ? submissionsStyles.required
                           : ''
                     }`}
                     country='US'
                     placeholder='Enter phone number'
                     value={telephone.value}
                     onChange={value => this.handleClick('telephone', value)}
                  />
               </div>

               <Input
                  label='Email'
                  name='email'
                  placeholder='Title'
                  type='email'
                  required={email.required}
                  value={email.value}
                  pattern='^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$'
                  showRequiredLine={this.fieldValidation(areRequired, 'email')}
                  onChange={(name, value) => this.handleClick(name, value)}
               />

               <Input
                  label='Credit Line (Please indicate how you would like to be credited as the owner of this work for publication)'
                  name='creditline'
                  placeholder='(e.g., “Collection of Jane Doe, New York” or “Private Collection, New York”)'
                  minLength='2'
                  maxLength='30'
                  type='text'
                  required={creditline.required}
                  value={creditline.value}
                  showRequiredLine={this.fieldValidation(
                     areRequired,
                     'creditline'
                  )}
                  onChange={(name, value) => this.handleClick(name, value)}
               />
            </div>

            <Button onClick={this.handleClickSendForm} isSubmit>
               Send form
            </Button>
         </form>
      ) : (
         <h2
            className={`${!isSended ? submissionsStyles.sending : ''} ${
               submissionsStyles.message
            }`}
         >
            {!isSended
               ? 'Sending message...'
               : 'The message was sent successfully'}
         </h2>
      )
   }
}

export default Submissions
