export const submissionsState = {
   clasification: { value: '', name: 'Clasification', required: true },
   title: { value: '', name: 'Title', required: true },
   isDated: {
      value: 'no',
      name: 'With date',
      required: true,
      children: ['date'],
   },
   date: { value: '', name: 'Date', required: false },
   medium: { value: '', name: 'Medium', required: true },
   support: { value: '', name: 'Support', required: true },
   dheight: { value: '', name: 'Dimmensions height', required: true },
   dwidth: { value: '', name: 'Dimmensions width', required: true },
   ddepth: { value: '', name: 'Dimmensions depth', required: true },
   dunit: { value: '', name: 'Dimmensions unit messure', required: true },

   edition: { value: '', name: 'Edition', required: false },

   documentationSale: {
      value: 'no',
      name: 'Documentation sale',
      required: true,
   },
   framed: {
      value: 'yes',
      name: 'Is framed',
      required: true,
      children: ['fheight', 'fwidth', 'fdepth', 'funit'],
   },
   fheight: { value: '', name: 'Frame height', required: true },
   fwidth: { value: '', name: 'Frame width', required: true },
   fdepth: { value: '', name: 'Frame medium', required: true },
   funit: { value: '', name: 'Frame medium', required: true },

   signed: {
      value: 'no',
      name: 'Signed',
      required: false,
      children: ['signature'],
   },
   signature: { value: '', name: 'Signature', required: false },
   additionalNotes: { value: '', name: 'Additional Notes', required: false },
   publicationHistory: {
      value: '',
      name: 'Publication History',
      required: false,
   },

   inscription: {
      value: 'no',
      required: false,
      name: 'Inscription',
      children: ['inscriptionLocation'],
   },
   inscriptionLocation: {
      value: '',
      name: 'Inscription location',
      required: false,
   },
   labels: {
      value: 'no',
      name: 'Labels',
      required: false,
      children: ['labelsList'],
   },
   labelsList: { value: '', name: 'Labels list', required: false },
   acquisition: { value: '', name: 'Acquisition', required: true },
   acquiredFrom: { value: '', name: 'Acquisition from', required: true },
   exhibitionHistory: {
      value: '',
      name: 'Exhibition history',
      required: false,
   },

   owner: { value: 'Owner', name: 'Owner', required: true },
   images: { value: [], name: 'Images', required: true },
   firstName: { value: '', name: 'Firstname', required: true },
   lastName: { value: '', name: 'Lastname', required: true },
   address: { value: '', name: 'Address', required: true },
   city: { value: '', name: 'City', required: true },
   state: { value: '', name: 'State', required: true },
   country: { value: '', name: 'Country', required: true },
   postalCode: { value: '', name: 'Postal code', required: true },
   creditline: { value: '', name: 'Creditline', required: true },
   telephone: { value: '', name: 'Telephone', required: true },
   email: { value: '', name: 'Email', required: true },
}

export const clasificationData = [
   { value: 'Collage', name: 'Collage' },
   { value: 'Drawing', name: 'Drawing' },
   { value: 'Painting', name: 'Painting' },
   { value: 'Print', name: 'Print' },
   { value: 'Sculpture', name: 'Sculpture' },
   { value: 'Textile', name: 'Textile' },
   { value: 'Watercolor', name: 'Watercolor' },
]

export const mediumData = [
   { value: 'Oil', name: 'Oil' },
   { value: 'Paper, charcoal, and ink', name: 'Paper, charcoal, and ink' },
   {
      value: 'Wood, pastel, nails, and glue',
      name: 'Wood, pastel, nails, and glue',
   },
]

export const supportData = [
   { value: 'Canvas', name: 'Canvas' },
   { value: 'Linen', name: 'Linen' },
   { value: 'Paper-on-canvas', name: 'Paper on canvas' },
   { value: 'Foam-board', name: 'Foam board' },
   { value: 'Paper', name: 'Paper' },
]

export const funitData = [
   { value: 'inches', name: 'Inches' },
   { value: 'centimeters', name: 'Centimeters' },
]

let hasOwnProperty = Object.prototype.hasOwnProperty

export function isEmpty(obj) {
   // null and undefined are "empty"
   if (obj == null) return true

   // Assume if it has a length property with a non-zero value
   // that that property is correct.
   if (obj.length > 0) return false
   if (obj.length === 0) return true

   // If it isn't an object at this point
   // it is empty, but it can't be anything *but* empty
   // Is it empty?  Depends on your application.
   if (typeof obj !== 'object') return true

   // Otherwise, does it have any properties of its own?
   // Note that this doesn't handle
   // toString and valueOf enumeration bugs in IE < 9
   for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false
   }

   return true
}
