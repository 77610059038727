import React, { useState, useEffect } from 'react'
import pageStyles from './page.module.scss'
import Container from '../../components/container/container.component'
import { graphql, navigate } from 'gatsby'
import Layout from '../../components/layout/layout.component'
import GalleryHorizontal from '../../components/galleryHorizontal/gallery.horizontal.component'
import { useScroll } from '../../hooks/scroll.hook'
import jump from 'jump.js'
import { useSize } from '../../hooks/size.hook'
import Submissions from '../../components/submissions/submissions.component'

//qery($slug: String!, $section: String = "/esteban-vicente") {
export const query = graphql`
   query($slug: String!) {
      site {
         siteMetadata {
            title
         }
      }
      nodePage(field_menu_link: { eq: $slug }, body: { value: { ne: null } }) {
         title
         field_menu_link
         field_description
         field_color_header
         field_show_contact
         field_show_footer
         field_show_submissions_form
         status
         body {
            value
            summary
         }
         relationships {
            field_header_image {
               localFile {
                  childImageSharp {
                     fixed(width: 1600, quality: 80) {
                        ...GatsbyImageSharpFixed
                     }
                  }
               }
            }
            field_sections {
               relationships {
                  imgDescktop: field_section_image {
                     localFile {
                        childImageSharp {
                           fluid(maxHeight: 400, quality: 100) {
                              ...GatsbyImageSharpFluid
                           }
                        }
                     }
                  }
                  imgMobile: field_section_image {
                     localFile {
                        childImageSharp {
                           fluid(maxHeight: 100, quality: 100) {
                              ...GatsbyImageSharpFluid
                           }
                        }
                     }
                  }
               }
               field_section_image {
                  width
                  title
               }
               field_section_text {
                  value
               }
               field_section_label
            }
         }
      }

      allMenuItems(
         filter: { childrenMenuItems: { elemMatch: { title: { ne: null } } } }
      ) {
         nodes {
            id
            title
            url
            weight
            childrenMenuItems {
               weight
               url
               title
            }
         }
      }
   }
`
//            url: { eq: $section }

export default function Page({ data }) {
   console.log('-----------', data)
   const {
      nodePage: {
         title,
         field_description,
         field_color_header,
         field_show_contact,
         field_show_footer,
         field_show_submissions_form,
         body,
         relationships,
      },
      allMenuItems,
      site: { siteMetadata },
   } = data

   const oneColumn = relationships.field_sections.length > 0 ? false : true
   const isDark = field_color_header === '#a77974' ? true : false
   const hasBackground = relationships.field_header_image ? true : isDark
   const colorHeader = isDark ? '#a77974' : hasBackground ? null : '#fcf6f3'
   const { isInFooter, scrollTop, isInBottom } = useScroll()
   const [offset_, setOffset_] = useState(0)
   const [positions, setPositions] = useState([])
   const [currentPos, setCurrentPos] = useState(null)
   const { isMobile, isMobLandscape } = useSize()

   /*
    *  Replace spaces, quotation marks, accents
    **/
   const replaceSlug = str => {
      const dict = { á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u', ç: 'c' }
      return str
         .replace(/\s/g, '-')
         .replace(/'/g, '')
         .replace(/"/g, '')
         .replace(/[^\w ]/g, char => dict[char] || char)
         .toLowerCase()
   }

   useEffect(() => {
      /*
       * reset initial position
       **/
      window.scrollTo(0, 0)

      /*
       * reset positions
       **/
      setPositions([])

      /*
       * blocks elements from backend
       **/
      const x = document.querySelectorAll('div.block-element')
      /*
       * Hash to compare what is the current category
       **/
      const hs = window.location.hash
      /*
       * Set offset for jump function when click on left menu and top gallery
       **/
      setOffset_(isMobile || isMobLandscape ? -160 : -289)

      /*
       * Set positions for elements or sections
       **/
      const screenHeight = window.innerHeight

      x.forEach((element, i) => {
         const position = Math.ceil(element.offsetTop - screenHeight / 4)
         /*
          * Set positions of blocks
          **/
         setPositions(positions => [...positions, { element, position }])

         /*
          * Find the section name to compare width hash
          **/
         const name =
            relationships?.field_sections[i] &&
            replaceSlug(relationships.field_sections[i].field_section_label)

         if (name.toLowerCase() === hs.substr(1)) {
            jump(position, {
               duration: 500,
               offset: -screenHeight / 70,
            })
         }
      })
   }, [isMobile])

   useEffect(() => {
      /*
       * Adding categories for current slug
       **/
      const pathname = window.location.pathname.split('/')
      //console.log(pathname, currentPos)
      if (
         currentPos !== null &&
         relationships.field_sections.length > 0 &&
         relationships.field_sections[0].field_section_label &&
         relationships.field_sections[currentPos]
      ) {
         const label = replaceSlug(
            relationships.field_sections[currentPos].field_section_label
         )

         navigate(
            `${'/esteban-vicente'}${
               pathname[2] ? '/' + pathname[2] : ''
            }?section=#${label}`
         )
      } else if (
         relationships.field_sections > 0 &&
         relationships.field_sections[0].field_section_label
      ) {
         navigate(
            `${'/esteban-vicente'}${
               pathname[2] ? '/' + pathname[2] : ''
            }?section=#all`
         )
      }
   }, [currentPos])

   useEffect(() => {
      /*
       * Current pos validation when you are scrolling
       **/
      let current = positions.findIndex(element => {
         const scroll = scrollTop + window.innerHeight / 5
         return (
            scrollTop > offset_ &&
            element.position >= scroll &&
            scroll <= element.position
         )
      })

      /*
       * If current dont exist adding the value of
       **/
      current =
         current - 1 < 0
            ? current - 1 === -2
               ? positions.length - 1
               : 0
            : current - 1
      /*
       * Set headerElement height for first current pos
       **/
      const headerElement = document.querySelector('.header-element')
         .scrollHeight
      setCurrentPos(scrollTop > headerElement ? current : null)
   }, [scrollTop, offset_, isInBottom, positions])

   //handle left Buttons
   const handleClick = pos => {
      jump(positions[pos].element, {
         duration: 500,
         offset: offset_,
      })
   }
   //handle horizontal gallery
   const handleGallery = name => {
      relationships &&
         relationships.field_sections.forEach((section, i) => {
            if (section.field_section_label === name) {
               jump(positions[i].element, {
                  duration: 500,
                  offset: offset_,
               })
            }
         })
   }

   const ContentSection = ({ body, fieldSection }) => {
      return (
         <React.Fragment>
            <div className='custom-block header-element'>
               <div className='custom-block-body'>
                  <div dangerouslySetInnerHTML={{ __html: body }} />
               </div>
            </div>

            {fieldSection &&
               fieldSection.map((value, key) => {
                  return (
                     value.field_section_text && (
                        <div key={key} className='custom-block block-element'>
                           <div className='custom-block-body'>
                              <hr />
                              <div
                                 dangerouslySetInnerHTML={{
                                    __html: value.field_section_text.value,
                                 }}
                              />
                           </div>
                        </div>
                     )
                  )
               })}
         </React.Fragment>
      )
   }

   return (
      <Layout
         title={`${title} - ${siteMetadata.title}`}
         name={title}
         description={field_description}
         hasBackground={hasBackground}
         secondary
         menuData={allMenuItems?.nodes[0]}
         showFooter={!!field_show_footer}
         showContact={field_show_contact}
         isMenuWhite={hasBackground}
         backgroundImageLayout={
            relationships.field_header_image &&
            relationships.field_header_image.localFile
               ? relationships.field_header_image.localFile.childImageSharp
                    .fixed
               : false
         }
         colorHeader={colorHeader}
         isMoving={scrollTop > 100}
         className={pageStyles.load}
      >
         {relationships.field_sections.length > 0 && (
            <GalleryHorizontal
               isDark={isDark}
               content={relationships.field_sections}
               onClick={handleGallery}
               isMoving={scrollTop > 100}
            />
         )}
         <Container oneColumn={oneColumn}>
            <div
               className={`
             ${pageStyles.wrapper}
             ${scrollTop > 100 && !isMobile ? pageStyles.scrolling : ''}
             ${
                relationships.field_header_image
                   ? pageStyles.withBackground
                   : ''
             }
             ${
                relationships.field_sections.length > 0
                   ? pageStyles.galleryHorizontal
                   : pageStyles.noGalleryHorizontal
             }
          `}
            >
               {!oneColumn ? (
                  <React.Fragment>
                     {/*Two Columns - Left Column*/}
                     <aside className={`${pageStyles.leftColumn} `}>
                        <div
                           className={`${pageStyles.listWrapper} ${
                              isInFooter ? pageStyles.isInFooter : ''
                           }`}
                        >
                           {relationships.field_sections &&
                              relationships.field_section_label && (
                                 <h3>
                                    {
                                       relationships.field_sections
                                          .field_section_label
                                    }
                                 </h3>
                              )}
                           <ul
                              className={`
                        ${pageStyles.list}
                        ${
                           relationships.field_sections.field_section_label
                              ? ''
                              : pageStyles.listMenu
                        }
                        ${
                           relationships &&
                           relationships.field_sections.length > 10
                              ? pageStyles.twoColumns
                              : ''
                        }
                `}
                           >
                              {relationships &&
                                 relationships.field_sections.map(
                                    (element, i) => {
                                       return (
                                          <li key={element.field_section_label}>
                                             <button
                                                className={
                                                   currentPos === i
                                                      ? pageStyles.active
                                                      : ''
                                                }
                                                onClick={() => handleClick(i)}
                                             >
                                                {element.field_section_label}
                                             </button>
                                          </li>
                                       )
                                    }
                                 )}
                           </ul>
                        </div>
                     </aside>

                     {/*Right Column*/}
                     <main className={pageStyles.rightColumn}>
                        <div className={pageStyles.contentWrapper}>
                           <div className='custom-block header-element'>
                              <div className='custom-block-body'>
                                 <div
                                    dangerouslySetInnerHTML={{
                                       __html: body.value,
                                    }}
                                 />
                              </div>
                           </div>
                           {relationships.field_sections &&
                              relationships.field_sections.map((value, key) => {
                                 return (
                                    value.field_section_text && (
                                       <div
                                          key={key}
                                          tabIndex={-key}
                                          className='custom-block block-element'
                                       >
                                          <div className='custom-block-body'>
                                             <hr />
                                             <div
                                                dangerouslySetInnerHTML={{
                                                   __html:
                                                      value.field_section_text
                                                         .value,
                                                }}
                                             />
                                          </div>
                                       </div>
                                    )
                                 )
                              })}
                        </div>
                     </main>
                  </React.Fragment>
               ) : (
                  <div
                     className={`${pageStyles.oneColumn} 
              ${!hasBackground && pageStyles.noBackground}`}
                  >
                     {/*One Column*/}
                     <ContentSection
                        body={body.value}
                        fieldSection={relationships.field_sections}
                     />
                     {field_show_submissions_form && <Submissions />}
                  </div>
               )}
            </div>
         </Container>
      </Layout>
   )
}
