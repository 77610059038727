export const data = {
  name: null,
  images: [
    {
      name: '1933',
      imageUrl: '../../imagesartworks/5-decades/C96-02_TMS_2015-07-23_AR.jpg',
    },
    {
      name: '1934',
      imageUrl: '../../images/artworks/5-decades/C88-16_TMS_2015-07-15_HPS.jpg',
    },
    {
      name: '1935',
      imageUrl:
        '../../images/artworks/5-decades/C68-07_TMS_2015-07-08_AR_002.jpg',
    },
    {
      name: '1936',
      imageUrl:
        '../../images/artworks/5-decades/C78-06_TMS_2015-07-09_UNKNOWN.jpg',
    },
    {
      name: '1937',
      imageUrl:
        '../../images/artworks/5-decades/C53-02_TMS_2015-03-10_UNKNOWN_001.jpg',
    },
  ],
}
